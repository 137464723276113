<template>
  <div>
    <b-card >
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/user_1.png"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Обиджон Маҳмудов <br />(1871-1936)</p></b-card-text
          >
        </b-col>
        <b-col md="6">
          <b-card-text>
          <p class="TextBlack17">
            Туркистондаги жадидчилик ҳаракати вакили, йирик сармоядор, ношир ва
            жамоат арбоби ва сиёсат арбоби Обиджон Aбдулхолиқ ўғли Маҳмудов
            Фарғона тараққийпарварларининг пешқадам вакилидир. У 1871 йили
            Марғилонда туғилган. Тахминан 1898 йилда Челекен ярим ороли (Каспий
            денгизи)га бориб, турли ишлар билан машғул бўлди. Санкт-Петербург
            университетининг тоғ-кон муҳандислиги факултетини тугатган. У
            халқимиз орасидан йетишиб чиққан биринчи тоғ-кон муҳандисидир.
            Қўқонга қайтгач, тоғ-кон саноати билан шуғулланиб, Фарғона водийсида
            бир неча нефт конларини очишда иштирок етган. Шўрсувдаги олтингугурт
            конини сотиб олган. Фарғона водийсидаги жадидчилик ҳаракатида
            қатнашган ва уларни моддий жиҳатдан таъминлаб турган. Қўқонда
            босмахона ташкил қилган (1914). «Садои Фарғона» (1914), «Тирик сўз»
            (1917) ва рус тилида чиққан «Ферганское ехо» (1914) газеталарининг
            муҳаррири ва ношири. Бу нашрлар нафақат Туркистонда, балки Россия
            мусулмонлари орасида ҳам машҳур бўлган. Обиджон Маҳмудов жадид
            матбуотида долзарб мақолалари билан қатнашиб, маърифат, озодлик,
            ҳурлик ғояларини тарғиб етишга, халқнинг онгини, савиясини кўтаришга
            интилди. О.Маҳмудов Туркистоннинг мустақил республика бўлиши
            тарафдори ва бунга йетишиш учун фаол ҳаракат қилган жадидлардан бири
            еди. У Қўқон шаҳар Думаси раиси ўринбосари (1917), Туркистон
            Мухторияти ҳукуматининг озиқ-овқат вазири (1917—18), Мухторият
            тугатилгач, Москвага бориб, РСФСР Миллатлар ишлари халқ комиссарлиги
            қошидаги Туркистон ваколатхонасида матбуот бўлими мудири ва бошқа
            лавозимларда ишлаган (1919—21). Тошкентга қайтгандан сўнг, дастлаб
            Туркистон МИҚ да ишлаган (1921), сўнг Фарғона водийсига бориб,
            «Миллий иттиҳод» ташкилотининг бўлимини тузган ва советларга қарши
            фаолият олиб борган. Совет режими томонидан бир неча марта қамоққа
            олинган. Aтоқли маърифатпарвар Обиджон Маҳмудов 1936 йилнинг 21
            ноябрида оғир хасталиқдан вафот етди.
          </p>
        </b-card-text>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
        
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
